<template>
  <div>
    <Loading v-bind:showLoadScreen="screenLoading" />
    <Error v-bind:errors="errors" />

    <div v-if="authenticated === 'INIT'">
      <Border v-bind:showLoading="checkingUser"><br />Checking user status...</Border>
    </div>

    <div v-if="authenticated === 'NO'">
      <Border v-bind:showLoading="checkingUser">
        <p style="text-align: center" class="marketing">A revolutionary rewards management system cultivating loyalty.
        </p>
        <!-- <p style="text-align: center" class="marketing">This user-friendly platform simplifies rewards creation and
          redemption.</p>
        <p style="text-align: center" class="marketing">"See You Tomorrow" empowers you to create meaningful, lasting
          relationships,
          transforming loyalty into an engaging and rewarding journey.</p> -->


        <div class="item">
          <div class="lineItem">
            Introducing See You Tomorrow, an application that brings the concept of rewards into every aspect of
            life. Whether you're a business owner looking to incentivise customer loyalty, a parent encouraging your
            children's progress, or anyone in between, our app is your partner in tracking and celebrating achievements.
          </div>
          <div class="lineItem">
            Create rewards, and watch as every completed action brings you or your participants closer to a
            well-deserved reward. With See You Tomorrow, motivation is always at your fingertips, and every
            accomplishment
            is acknowledged and celebrated.
          </div>
          <div class="lineItem">
            Embrace the power of positive reinforcement and let See You Tomorrow help you build a culture of success and
            gratification, no matter the setting!
          </div>

          <div class="lineItem">
            To get started, sign in and explore what the application can do for you. Did we mention its free (for basic
            use)
          </div>
        </div>
      </Border>
      <!-- <Border class="center">
        <div class="centerOfRow">
          <button @click="loginWithFacebook()">Login with Facebook</button>
        </div>
      </Border> -->
      <Border class="center">
        <div class="centerOfRow">
          <button @click="loginWithGoogle()">Login with Google</button>
        </div>
      </Border>

      <div class="center">
        <div @click="showPrivacypolicy = true"><a href="#privacy">Privacy policy</a></div>
        <HelpModal v-bind:showModal="showPrivacypolicy" helpHeader="" helpText=""
          @closeHelpModal="showPrivacypolicy = false">
          <div slot="header">Privacy policy</div>
          <div slot="body">
            <Privacy></Privacy>
          </div>
        </HelpModal>

        <div @click="showTerms = true"><a href="#terms">Terms of use</a></div>
        <HelpModal v-bind:showModal="showTerms" helpHeader="" helpText="" @closeHelpModal="showTerms = false">
          <div slot="header">Terms of use</div>
          <div slot="body">
            <Terms></Terms>
          </div>
        </HelpModal>
      </div>
    </div>

    <div v-if="authenticated === 'YES'">
      <div v-if="sytUser.status === 'INIT'">
        <Border>
          <p class="centerOfRow">Complete your user setup</p>
          <p class="centerOfRow">Select what type of user you are</p>
          <ul>
            <li>
              <input type="radio" v-model="userTypeSelected" v-bind:value="userTypeSelectedPatron" />Customer - you buy
              and want to be rewarded
            </li>
            <li>
              <input type="radio" v-model="userTypeSelected" v-bind:value="userTypeSelectedVendor" />Vendor - you sell
              and
              reward loyal customers
            </li>
          </ul>

          <div class="centerOfRow" v-if="userTypeSelected === 'PATRON'">
            <button @click="finaliseUser">save</button>
          </div>

          <div class="centerOfRow" v-if="userTypeSelected === 'VENDOR'">
            <input class="rcornersInput" v-model="sytUser.vendorName" placeholder="Business name" />
            <button @click="finaliseUser">save</button>
          </div>
        </Border>
      </div>

      <div v-if="sytUser.status === 'ACTIVE'">
        <Border>
          <div class="item">
            <div class="lineItemHeader">Hi {{ sytUser.name }}</div>
          </div>
        </Border>

        <HelpModal v-bind:showModal="showRewardsHelp" helpHeader="" helpText=""
          @closeHelpModal="showRewardsHelp = false">
          <div slot="header">Rewards you offer</div>
          <div slot="body">
            <div>As a vendor, all the rewards listed here are rewards you currently offer to your customers. If nothing
              shows in this list, you need to create rewards in the 'Admin', 'Create and change rewards' section.
            </div>
            <br />
            <div>1. Rewards encompass a variety of offerings that you extend to your customers. These can take the form
              of
              discounts, free items, vouchers, or special services.
              By offering rewards, you create a positive feedback loop: Customers feel appreciated, and in return,
              they continue to choose your products or services.</div>
            <br />
            <div><strong>2. Quantity-Based Rewards:</strong> These are tied to the number of times a customer makes a
              specific purchase. For instance, you might offer a free muffin after a customer buys 10 cups of coffee.
            </div><br />
            <div><strong>3. Amount-Based Rewards:</strong> These depend on the total amount of money spent by a
              customer.
              For example, you could provide a 10% discount coupon for every €100 spent.</div><br />
            <div><strong>4. Frequency-Based Rewards:</strong> These are linked to how often a customer engages with your
              business. Offering free delivery after 5 orders placed in a month is an example.</div>
            <br />
            <div>5. You have the flexibility to tailor rewards to align with your business goals, customer preferences,
              and budget. When creating rewards, consider factors such as expiration dates, redemption limits, and
              eligibility
              criteria. To manage rewards, navigate to the 'Admin' section and select 'Create and Change Rewards.' Here,
              you
              can add, edit, or delete rewards as needed.</div>
            <br />
            <div>6. Benefits of rewards are:</div>
            <ul>
              <li><strong>Customer Retention:</strong> Rewards keep customers coming back, reinforcing their loyalty.
              </li>
              <li><strong>Increased Sales:</strong> Incentivized customers are more likely to make additional purchases.
              </li>
              <li><strong>Competitive Edge:</strong> Offering unique rewards sets you apart from competitors.</li>
              Remember, rewards are a powerful tool in building lasting relationships with your customers.
              Start creating and customizing rewards today to enhance your business!
            </ul>
          </div>
        </HelpModal>
        <div v-if="sytUser.type === 'VENDOR'">
          <div v-if="!showRewardsOnOfferDetails">
            <Border>
              <div class="lineItem item3">
                <img src="../assets/q.png" class="imgIcon right smaller" @click="rewardsHelp()" />
                <div @click="toggleShowRewardsOnOfferDetails()">Rewards you offer
                  <img src="../assets/down.png" class="imgIcon right smaller" />
                </div>
              </div>
            </Border>
          </div>
          <div v-if="showRewardsOnOfferDetails">
            <Border v-bind:showLoading="loadingRewards">
              <div class="lineItem item3">
                Rewards you offer
                <img src="../assets/q.png" class="imgIcon right smaller" @click="rewardsHelp()" />
                <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleShowRewardsOnOfferDetails()" />
              </div>
              <div v-if="rewards.length > 0 && !loadingRewards">
                <div class="item" v-for="reward in rewards" v-bind:key="reward.rewardId">
                  <div v-if="reward.rewardSummary" class="lineItem">
                    <img src="../assets/down.png" class="imgIcon right smaller" @click="rewardDetails(reward)" />
                    <img src="../assets/edit4.png" class="imgIcon right smaller" @click="gotoRewards(reward)" />
                    <div class="lineItem">{{ reward.rewardName }}</div>
                  </div>
                  <div v-if="!reward.rewardSummary" class="lineItem">
                    <img src="../assets/up.png" class="imgIcon right smaller" @click="rewardDetails(reward)" />
                    <div class="lineItemHeader">
                      ~ {{ reward.rewardName }} ~
                    </div>
                    <div class="lineItem">
                      {{ reward.actualReward }} after
                      {{ reward.redeemAmount }} scanned events
                    </div>
                    <div class="lineItem">
                      Valid from: {{ reward.startDate }} to {{ reward.endDate }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="rewards.length < 1 && !loadingRewards">
                As a Vendor you currently offer no rewards to loyal customers. Go
                to 'Admin' and create some rewards!
              </div>
              <div v-if="loadingRewards"><br />Checking rewards...</div>
            </Border>
          </div>
        </div>

        <HelpModal v-bind:showModal="showTracksHelp" helpHeader="" helpText="" @closeHelpModal="showTracksHelp = false">
          <div slot="header">Rewards in progress</div>
          <div slot="body">
            Welcome to the rewards tracking section! Here, you can conveniently view the rewards you're currently
            keeping tabs on. If you don't see anything listed here, it means you haven't yet been rewarded for a
            purchase from a vendor using this application.<br /><br />

            To begin your rewards journey, follow these steps:<br /><br />

            1. Look out for products that come with reward programs. Vendors or businesses will
            provide information about products eligible for rewards or use the 'Search for vendors' fucntionality at the
            bottom of the 'Home' page<br /><br />

            2.Once you've purchased a qualifying product, hit the "Get Scanned" button. This
            action
            generates a unique QR code.<br /><br />

            3. Present the QR code to the vendor or business owner. They'll scan the code to
            register
            your reward tracking.<br /><br />

            4. Once the QR code is scanned, your reward tracking will be visible in this section.
            Keep an eye on your progress as you work towards unlocking exciting rewards.<br /><br />

            By actively participating in the rewards tracking process, you'll enjoy the benefits of your interactions
            and
            engage in a seamless, rewarding experience. Happy tracking!
          </div>
        </HelpModal>
        <div v-if="!showCurrentTracks">
          <Border>
            <div class="lineItem item3">
              <img src="../assets/q.png" class="imgIcon right smaller" @click="tracksHelp()" />
              <div @click="toggleShowCurrentTracks()">Rewards in progress
                <img src="../assets/down.png" class="imgIcon right smaller" />
              </div>
            </div>
          </Border>
        </div>
        <div v-if="showCurrentTracks">
          <Border v-bind:showLoading="loadingTracks">
            <div class="lineItem item3">
              Rewards in progress
              <img src="../assets/q.png" class="imgIcon right smaller" @click="tracksHelp()" />
              <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleShowCurrentTracks()" />
            </div>
            <div v-if="tracking.length > 0 && !loadingTracks">
              <div class="item" v-for="track in tracking" v-bind:key="track.trackId">
                <div v-if="track.trackSummary" class="lineItem">
                  <img src="../assets/down.png" class="imgIcon right smaller" @click="trackDetails(track)" />
                  <div class="lineItem">
                    {{ track.rewardName }} @ {{ track.vendorName }} ({{
      track.count
    }}
                    from {{ track.goal }})
                  </div>
                </div>
                <div v-if="!track.trackSummary" class="lineItem">
                  <img src="../assets/up.png" class="imgIcon right smaller" @click="trackDetails(track)" />
                  <div class="lineItemHeader">
                    ~ {{ track.rewardName }} reward ~
                  </div>
                  <div class="lineItem">
                    You have {{ track.count }} out of {{ track.goal }} from the
                    {{ track.rewardName }} reward
                  </div>
                  <!-- <div class="lineItem">
                    Only {{}} more to go then you get a reward
                  </div> -->
                  <div class="lineItem">
                    Once you reach your goal, {{ track.vendorName }} will reward
                    you with a {{ track.actualReward }}
                  </div>
                </div>
              </div>
              <div class="center">
                <button @click="redeem()">Get scanned</button>
              </div>
            </div>
            <div v-if="tracking.length < 1 && !loadingTracks">
              No active rewards to track. Buy something to get rewarded!

              <div class="center">
                <button @click="redeem()">Get scanned</button>
              </div>
            </div>
            <div v-if="loadingTracks"><br />Checking tracks...</div>
          </Border>
        </div>

        <HelpModal v-bind:showModal="showVouchersHelp" helpHeader="" helpText=""
          @closeHelpModal="showVouchersHelp = false">
          <div slot="header">Claim your rewards</div>
          <div slot="body">
            Welcome to the vouchers section! This is where all your hard-earned rewards come to life. Here's a
            detailed
            breakdown of how it works:<br /><br />

            1. Vouchers are your well-deserved completed rewards. You earn them by successfully completing
            rewards. A reward is achieved when you make the required purchase of a specific product linked to a reward
            program.<br /><br />

            2. When you've met the necessary purchase criteria for a reward, it's considered
            completed. Once completed, the reward will no longer be visible in the tracking section. Instead, it will
            transform into a voucher. Vouchers represent your accomplishments<br /><br />

            3. To claim your reward, click the "Generate" button associated with the voucher.
            This action creates a QR code, which you must present to the vendor or business offering the
            reward.<br /><br />

            4. When you visit the vendor or business, present the QR code for scanning. The code
            validates your eligibility for the reward. Upon verification, you'll receive the reward - whether it's a
            discount, a free item, or another enticing offer.<br /><br />

            5. Once your voucher has been successfully scanned and redeemed, it will be removed from
            this section. Only vouvhers that can be used appear in this section.<br /><br />

            7. Keep an eye on the expiry date of your vouchers. Each voucher comes with a
            limited validity period, so make sure to utilize them before they expire!<br /><br />

            By actively participating in this process, you're unlocking a world of rewards. Stay informed, redeem
            timely, and enjoy the benefits of your loyalty. Don't miss out! Start generating vouchers and
            experiencing
            the joy of rewards today!
          </div>
        </HelpModal>
        <div v-if="!showCurrentVouchers">
          <Border>
            <div class="lineItem item3">
              <img src="../assets/q.png" class="imgIcon right smaller" @click="vouchersHelp()" />
              <div @click="toggleShowCurrentVouchers()">Claim your rewards
                <img src="../assets/down.png" class="imgIcon right smaller" />
              </div>
            </div>
          </Border>
        </div>
        <div v-if="showCurrentVouchers">
          <Border v-bind:showLoading="loadingVouchers">
            <div class="lineItem item3">
              Claim your rewards
              <img src="../assets/q.png" class="imgIcon right smaller" @click="vouchersHelp()" />
              <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleShowCurrentVouchers()" />
            </div>
            <div v-if="vouchers.length > 0 && !loadingVouchers">
              <div class="item" v-for="voucherInList in vouchers" v-bind:key="voucherInList.voucherId">
                <div class="lineItem" v-if="voucherInList.displaySummary">
                  <img src="../assets/down.png" class="imgIcon right smaller" @click="voucherDetails(voucherInList)" />
                  <div class="lineItem">
                    <div v-if="voucherInList.expireIn > 1">
                      {{ voucherInList.rewardName }} from
                      {{ voucherInList.vendorName }} - expires in
                      {{ voucherInList.expireIn }} days
                    </div>
                    <div v-if="voucherInList.expireIn == 1">
                      {{ voucherInList.rewardName }} from
                      {{ voucherInList.vendorName }} - expires tomorrow!
                    </div>
                    <div v-if="voucherInList.expireIn == 0">
                      {{ voucherInList.rewardName }} from
                      {{ voucherInList.vendorName }} - expires today!
                    </div>
                  </div>
                </div>
                <div class="lineItem" v-if="!voucherInList.displaySummary">
                  <img src="../assets/up.png" class="imgIcon right smaller" @click="voucherDetails(voucherInList)" />
                  <div class="lineItemHeader">
                    ~ {{ voucherInList.rewardName }} reward ~
                  </div>
                  <div class="lineItem">
                    You have a reward waiting for you at
                    {{ voucherInList.vendorName }}. The reward is a
                    {{ voucherInList.actualReward }}
                    {{ voucherInList.displayDetail }}
                  </div>
                  <div class="lineItem">
                    Click the redeem button and show your voucher QR to the vendor
                  </div>
                  <div class="lineItem center">
                    <button @click="generateReward(voucherInList)">Redeem</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="vouchers.length < 1 && !loadingVouchers">
              <h3>No vouchers found</h3>
              Vouchers will display here when you are eligible
              for rewards.
            </div>
            <div v-if="loadingVouchers"><br />Checking vouchers...</div>
          </Border>
        </div>
        <HelpModal v-bind:showModal="showVouchersHistoryHelp" helpHeader="" helpText=""
          @closeHelpModal="showVouchersHistoryHelp = false">
          <div slot="header">Claimed rewards</div>
          <div slot="body">
            This section displays all the rewards you've claimed. <br /><br />

            1. Any vouchers that you've redeemed at a participating vendor or business are showcased here<br /><br />

            2. The list represents all the rewards you've claimed from vendors or businesses. It provides a great
            overview of your achievements.<br /><br />

            3. We show the most recent 30 rewards in your rewards journey. If you'd like to see more, please don't
            hesitate to contact us.<br /><br />

            By actively participating in this process, you've unlocked a world of rewards! Well done!
          </div>
        </HelpModal>
        <div v-if="!showHistoryVouchers">
          <Border>
            <div class="lineItem item3">
              <img src="../assets/q.png" class="imgIcon right smaller" @click="vouchersHistoryHelp()" />
              <div @click="toggleShowHistoryVouchers()">Claimed rewards
                <img src="../assets/down.png" class="imgIcon right smaller" />
              </div>
            </div>
          </Border>
        </div>
        <div v-if="showHistoryVouchers">
          <Border v-bind:showLoading="loadingHistoryVouchers">
            <div class="lineItem item3">
              Claimed rewards
              <img src="../assets/q.png" class="imgIcon right smaller" @click="vouchersHistoryHelp()" />
              <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleShowHistoryVouchers()" />
            </div>
            <div v-if="vouchersHistory.length > 0 && !loadingHistoryVouchers">
              <div class="item" v-for="voucherHistoryInList in vouchersHistory"
                v-bind:key="voucherHistoryInList.voucherId">
                <div class="lineItem">
                  {{ voucherHistoryInList.rewardName }} from
                  {{ voucherHistoryInList.vendorName }} on
                  {{ voucherHistoryInList.redeemDate }} to get
                  {{ voucherHistoryInList.actualReward }}
                </div>
              </div>
            </div>
            <div v-if="vouchersHistory.length < 1 && !loadingHistoryVouchers">
              <h3>No history found</h3>
              Vouchers will display here if you have claimed them.
            </div>
            <div v-if="loadingHistoryVouchers"><br />Checking vouchers history...</div>
          </Border>
        </div>

        <HelpModal v-bind:showModal="showSearchVendorHelp" helpHeader="" helpText=""
          @closeHelpModal="showSearchVendorHelp = false">
          <div slot="header">Search for vendors</div>
          <div slot="body">
            In section you can search for vendors or business that participate in the 'See you tomorrow' program.
            <br /><br />

            1. Type at least the starting 3 charachters of the vendor or business name to start your search.<br /><br />

            2. If the vendor or business have signed up to use 'See you tomorrow' they will be listed as search
            results.<br /><br />

            3. Click on the expand down arrow to view the rewards of a particular business.<br /><br />

            To enjoy rewards ensure that you vist the vendor or business!
          </div>
        </HelpModal>
        <div v-if="!showParticipatingVendors">
          <Border>
            <div class="lineItem item3">
              <img src="../assets/q.png" class="imgIcon right smaller" @click="searchVendorHelp()" />
              <div @click="toggleShowParticipatingVendors()">Search for vendors
                <img src="../assets/down.png" class="imgIcon right smaller" />
              </div>
            </div>
          </Border>
        </div>
        <div v-if="showParticipatingVendors">
          <Border v-bind:showLoading="loadingVendors">
            <div class="lineItem item3">
              Search for vendors
              <img src="../assets/q.png" class="imgIcon right smaller" @click="searchVendorHelp()" />
              <img src="../assets/up.png" class="imgIcon right smaller" @click="toggleShowParticipatingVendors()" />
            </div>
            <input class="rcornersInput" v-model="searchVendorOnName"
              placeholder="Type vendor name - min 3 charachters" />
            <div v-if="vendorList.length > 0 && !loadingVendors">
              <div class="item" v-for="vendorFound in vendorList" v-bind:key="vendorFound.userID">
                <div>
                  <div class="lineItem" v-if="!showRewardsForVendor">
                    <div class="lineItem">{{ vendorFound.vendorName }}
                      <img src="../assets/down.png" class="imgIcon right smaller"
                        @click="getSelectedVendorRewards(vendorFound)" />
                    </div>
                  </div>
                  <div v-if="showRewardsForVendor">
                    <div>
                      <div class="lineItem "> {{ vendorFound.vendorName }}
                        <img src="../assets/up.png" class="imgIcon right smaller"
                          @click="toggleShowRewardsForVendor()" />
                      </div>
                    </div>

                    <div v-if="selectedVendorRewards && selectedVendorRewards.length > 0">
                      <div class="item" v-for="reward in selectedVendorRewards" v-bind:key="reward.rewardId">
                        <div class="lineItemHeader">
                          ~ {{ reward.rewardName }} reward ~
                        </div>
                        <div class="lineItem">
                          {{ reward.actualReward }} after
                          {{ reward.redeemAmount }} scanned events
                        </div>
                        <div class="lineItem">Valid from: {{ reward.startDate }} to {{ reward.endDate }}</div>
                      </div>
                    </div>

                    <div v-if="selectedVendorRewards && selectedVendorRewards.length < 1">
                      <div>~ No rewards found ~</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div v-if="vendorList.length < 1 && !loadingVendors && searchVendorOnNameDone && !showRewardsForVendor">
              <h3>No vendors found</h3>
              Try searching again
            </div>
            <div v-if="loadingVendors"><br />Searching for vendors</div>
          </Border>
        </div>
        <!-- <div v-if="tracking.length < 1 && sytUser.type === 'PATRON'">
          <Border
            ><b
              >You are currently tracking no rewards. Buy something!!</b
            ></Border
          >
        </div> -->
      </div>

      <div v-if="sytUser.status === 'DISABLED'">User disabled. Sorry</div>
    </div>
  </div>
</template>

<script>
import Border from "@/components/Border.vue";
import HelpModal from "@/components/HelpModal.vue";
import Error from "@/components/Error.vue";
import Loading from "@/components/Loading.vue";
import Comms from "../shared";
import Terms from "@/components/Terms.vue";
import Privacy from "@/components/Privacy.vue";
export default {
  name: "Home",
  components: {
    Border,
    Error,
    Loading,
    Terms,
    Privacy,
    HelpModal,
  },
  data: function () {
    return {
      errors: [],
      showRewardsOnOfferDetails: false,
      showVouchersHistoryHelp: false,
      showPrivacypolicy: false,
      showTerms: false,
      showHistoryVouchers: false,
      showParticipatingVendors: false,
      showCurrentTracks: false,
      showCurrentVouchers: false,
      screenLoading: false,
      loadingRewards: false,
      loadingHistoryVouchers: false,
      loadingTracks: false,
      loadingVouchers: false,
      loadingVendors: false,
      checkingUser: false,
      showRewardsHelp: false,
      showTracksHelp: false,
      showVouchersHelp: false,
      showSearchVendorHelp: false,
      showRewardsForVendor: false,
      userTypeSelected: "INIT",
      userTypeSelectedPatron: "PATRON",
      userTypeSelectedVendor: "VENDOR",
      socialLoginUser: null,
      sytUser: {
        userID: "",
        userName: "",
        type: "",
        status: "",
        vendorName: "",
        name: "",
        picture: "",
        operatingAsVendor: "",
        vendorDelegates: [],
      },
      authenticated: "INIT", //init, yes, no
      searchVendorOnName: "",
      searchVendorOnNameDone: false,
      vendorList: [],
      selectedVendorRewards: [],
      tracking: [],
      track: {
        trackId: "",
        patronId: "",
        rewardId: "",
        vendorName: "",
        vendorId: "",
        status: "",
        actualReward: "",
        count: "",
        goal: "",
        difference: "",
        rewardName: "",
      },
      rewards: [],
      reward: {
        rewardId: "",
        vendorId: "",
        vendorName: "",
        rewardName: "",
        redeemAmount: "",
        startDate: "",
        endDate: "",
        actualReward: "",
      },
      vouchers: [],
      vouchersHistory: [],
      voucher: {
        voucherId: "",
        trackId: "",
        patronId: "",
        rewardId: "",
        vendorId: "",
        createDate: "",
        redeemDate: "",
        status: "",
        vendorName: "",
        rewardName: "",
        actualReward: "",
        voucherExpireDays: "",
        expireInXDays: "",
      },
    };
  },

  mounted: function () {
    this.screenLoading = true;
    this.errors = [];
    this.checkUser();
    // Comms.detectSwipe('app', (el, dir) => console.log(`you swiped on element with id ${el.id} to ${dir} direction`));
    // Comms.detectSwipe("app", (el, dir) => {
    //   if (dir === "left") {
    //     if (this.$route.name === "Home") {
    //       this.$router.push({ name: "Admin" });
    //     } else if (this.$route.name === "Admin") {
    //       this.$router.push({ name: "Scan" });
    //     } else if (this.$route.name === "Scan") {
    //       this.$router.push({ name: "QR" });
    //     }
    //   } else if (dir === "right") {
    //     if (this.$route.name === "QR") {
    //       this.$router.push({ name: "Scan" });
    //     } else if (this.$route.name === "Scan") {
    //       this.$router.push({ name: "Admin" });
    //     } else if (this.$route.name === "Admin") {
    //       this.$router.push({ name: "Home" });
    //     }
    //   // } else if (dir === "up") {
    //   //   // this.$router.push({ name: "Home" });
    //   //   window.location.reload();
    //   }
    // });
  },
  watch: {
    searchVendorOnName() {
      this.selectedVendorRewards = [];
      this.vendorList = [];
      this.reward = null;
      if (this.searchVendorOnName.length > 2) {
        this.showRewardsForVendor = false;
        this.searchVendorOnNameDone = false;
        //call server
        this.loadingVendors = true;
        Comms.sendToServer(
          "POST",
          "getVendors",
          this.searchVendorOnName,
          this.errors
        ).then((response) => {
          // console.log(response);
          if (response.status == 200) {
            this.vendorList = JSON.parse(response.data);
          }
          this.searchVendorOnNameDone = true;
          this.loadingVendors = false;
        });
      } else {
        this.searchVendorOnNameDone = false;
      }
    },
  },
  methods: {
    getSelectedVendorRewards(selectedVendor) {
      if (this.$store.state.authenticated && this.sytUser.status === "ACTIVE") {
        this.loadingVendors = true;
        Comms.sendToServer(
          "POST",
          "getActiveRewards",
          selectedVendor,
          this.errors
        ).then((response) => {
          // console.log(response);
          if (response.status == 200) {
            this.showRewardsForVendor = true;
            this.selectedVendorRewards = JSON.parse(response.data);
          }
          this.loadingVendors = false;
        });
      }
    },
    generateReward(voucherInList) {
      this.$store.commit("setVoucher", voucherInList);
      this.$router.push({ name: "QR" });
    },
    toggleShowParticipatingVendors() {
      if (this.showParticipatingVendors) {
        this.showParticipatingVendors = false;
      } else {
        this.showParticipatingVendors = true;
      }
    },
    toggleShowHistoryVouchers() {
      if (this.showHistoryVouchers) {
        this.showHistoryVouchers = false;
      } else {
        this.showHistoryVouchers = true;
        if (this.$store.state.authenticated && this.sytUser.status === "ACTIVE") {
          this.loadingHistoryVouchers = true;
          Comms.sendToServer(
            "POST",
            "getHistoriceVoucher",
            this.sytUser,
            this.errors
          ).then((response) => {
            if (response.status == 200) {
              this.vouchersHistory = JSON.parse(response.data);
              // set the display var on all vouchers
              // eslint-disable-next-line
              this.vouchersHistory.forEach(function (item, index) {
                // console.log(item, index);
                item.redeemDate = item.redeemDate.substring(0, 10);
              });
            }
            this.loadingHistoryVouchers = false;
          });
        }
      }
    },
    toggleShowRewardsForVendor() {
      if (this.showRewardsForVendor) {
        this.showRewardsForVendor = false;
      } else {
        this.showRewardsForVendor = true;
      }
    },
    toggleShowCurrentVouchers() {
      if (this.showCurrentVouchers) {
        this.showCurrentVouchers = false;
      } else {
        this.showCurrentVouchers = true;
        if (this.$store.state.authenticated && this.sytUser.status === "ACTIVE") {
          this.loadingVouchers = true;
          Comms.sendToServer(
            "POST",
            "getActiveVoucher",
            this.sytUser,
            this.errors
          ).then((response) => {
            // console.log("Vouchers: " + response.data);
            if (response.status == 200) {
              this.vouchers = JSON.parse(response.data);
              // set the display var on all vouchers
              // eslint-disable-next-line
              this.vouchers.forEach(function (item, index) {
                // console.log(item, index);
                item.displaySummary = true;
                // check how many days before voucher expires.

                let today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                // console.log("today: " + today);
                let dateArray = item.createDate.split("-");
                // console.log("dateArray: " + dateArray);
                let created = new Date(
                  dateArray[0],
                  dateArray[1] - 1,
                  dateArray[2].substring(0, 2),
                  0,
                  0,
                  0,
                  0
                );
                // created will always be before today
                // console.log("created: " + created);
                let diffDays = Math.ceil(
                  Math.abs(today - created) / (1000 * 60 * 60 * 24)
                );
                // console.log("diffDays: " + diffDays);
                let daysLeft = item.voucherExpireDays - diffDays;
                // console.log("Days left: " + daysLeft);
                item.expireIn = daysLeft;
              });
            }
            this.loadingVouchers = false;
          });
        }
      }
    },
    toggleShowCurrentTracks() {
      if (this.showCurrentTracks) {
        this.showCurrentTracks = false;
      } else {
        this.showCurrentTracks = true;
        if (this.$store.state.authenticated && this.sytUser.status === "ACTIVE") {
          this.loadingTracks = true;
          Comms.sendToServer(
            "POST",
            "getActiveTrack",
            this.sytUser,
            this.errors
          ).then((response) => {
            // console.log(response);
            if (response.status == 200) {
              this.tracking = JSON.parse(response.data);
              // eslint-disable-next-line
              this.tracking.forEach(function (item, index) {
                // console.log(item, index);
                item.trackSummary = true;
              });
            }
            this.loadingTracks = false;
          });
        }
      }
    },
    toggleShowRewardsOnOfferDetails() {
      if (this.showRewardsOnOfferDetails) {
        this.showRewardsOnOfferDetails = false;
      } else {
        this.showRewardsOnOfferDetails = true;
        // load the rewards
        if (this.$store.state.authenticated && this.sytUser.status === "ACTIVE") {
          // console.log("Global user ID" + this.$store.state.sytUser.userID);
          if (this.sytUser.type === "VENDOR") {
            this.loadingRewards = true;
            Comms.sendToServer(
              "POST",
              "getActiveRewards",
              this.sytUser,
              this.errors
            ).then((response) => {
              // console.log(response);
              if (response.status == 200) {
                this.rewards = JSON.parse(response.data);
                // eslint-disable-next-line
                this.rewards.forEach(function (item, index) {
                  item.rewardSummary = true;
                });
              }
              this.loadingRewards = false;
            });
          }
        }
      }
    },
    rewardsHelp() {
      this.showRewardsHelp = true;
    },
    vouchersHistoryHelp() {
      this.showVouchersHistoryHelp = true;
    },
    tracksHelp() {
      this.showTracksHelp = true;
    },
    vouchersHelp() {
      this.showVouchersHelp = true;
    },
    searchVendorHelp() {
      this.showSearchVendorHelp = true;
    },
    trackDetails(track) {
      let index = this.tracking.findIndex((x) => x.trackId === track.trackId);
      this.track = this.tracking[index];

      if (this.track.trackSummary) {
        this.track.trackSummary = false;
      } else {
        this.track.trackSummary = true;
      }
      // tell Vue that a dynamically added property changed on the object
      this.$set(this.tracking, index, this.track);
    },
    rewardDetails(reward) {
      let index = this.rewards.findIndex((x) => x.rewardId === reward.rewardId);
      this.reward = this.rewards[index];

      if (this.reward.rewardSummary) {
        this.reward.rewardSummary = false;
      } else {
        this.reward.rewardSummary = true;
      }
      // tell Vue that a dynamically added property changed on the object
      this.$set(this.rewards, index, this.reward);
    },
    voucherDetails(voucherInList) {
      // get the object from the list
      let index = this.vouchers.findIndex(
        (x) => x.voucherId === voucherInList.voucherId
      );
      this.voucher = this.vouchers[index];
      if (this.voucher.displaySummary) {
        this.voucher.displaySummary = false;
      } else {
        this.voucher.displaySummary = true;
      }
      // tell Vue that a dynamically added property changed on the object
      this.$set(this.vouchers, index, this.voucher);
    },
    loginWithFacebook() {
      this.screenLoading = true;
      this.$sytAuth.federatedSignIn({ provider: "Facebook" });
      this.checkUser();
    },
    loginWithGoogle() {
      this.screenLoading = true;
      this.$sytAuth.federatedSignIn({ provider: "Google" });
      this.checkUser();
    },
    getDaysLeft(createdDate, expireDays) {
      let today = new Date();
      let dateArray = createdDate.split("-");
      let created = new Date(
        dateArray[0],
        dateArray[1],
        dateArray[2].substring(0, 2),
        0,
        0,
        0,
        0
      );
      // created will always be before today
      let diffDays = Math.ceil(
        Math.abs(today - created) / (1000 * 60 * 60 * 24)
      );
      let daysLeft = expireDays - diffDays;
      return daysLeft;
    },
    loadLandingData() {
    },
    async checkUser() {
      if (this.$store.state.authenticated) {
        //user logged in previously
        // console.log("User already logged in");
        this.authenticated = "YES";
        this.sytUser = this.$store.state.sytUser;
        // this.loadLandingData();
      } else {
        // not authenticated. do the complete login check
        // console.log("User NOT logged in");
        try {
          this.checkingUser = true;
          this.socialLoginUser = await this.$sytAuth.currentAuthenticatedUser();
          // console.log(this.socialLoginUser);
          if (this.socialLoginUser.username) {
            this.sytUser.userName = this.socialLoginUser.attributes.email;
            this.sytUser.name = this.socialLoginUser.attributes.name;
            Comms.sendToServer(
              "POST",
              "addUser",
              this.sytUser,
              this.errors
            ).then((response) => {
              // console.log("response.data" + response.data);
              // console.log("response.status" + response.status);
              if (response.status === 200) {
                this.sytUser = JSON.parse(response.data);
                //Check status
                if (this.sytUser.status === "INIT") {
                  this.authenticated = "YES";
                } else if (this.sytUser.status === "ACTIVE") {
                  this.$store.commit("updateUser", this.sytUser);
                  this.$store.commit("setAuthStatus", true);
                  this.authenticated = "YES";
                  // this.loadLandingData();
                } else {
                  // console.log("Unknown user status: " + this.sytUser.status);
                }
              }
              this.checkingUser = false;
            });
          } else {
            this.checkingUser = false;
            this.authenticated = "NO";
            this.$store.commit("setAuthStatus", false);
          }
        } catch (e) {
          // Promise throws error when user not authenticated.
          this.authenticated = "NO";
          this.$store.commit("setAuthStatus", false);
          this.checkingUser = false;
        }
      }
      this.screenLoading = false;
    },
    finaliseUser() {
      this.sytUser.status = "ACTIVE";
      this.sytUser.type = this.userTypeSelected;
      Comms.sendToServer("POST", "updateUser", this.sytUser, this.errors).then(
        (response) => {
          // console.log(response);
          if (response.status === 200) {
            this.$store.commit("updateUser", this.sytUser);
            this.$store.commit("setAuthStatus", true);
            this.authenticated = "YES";
            // this.loadLandingData();
          }
        }
      );
    },
    redeem() {
      this.$router.push({ name: "QR" });
    },
    gotoRewards(selectedReward) {
      if (this.$store.state.sytUser.type === "VENDOR") {
        this.$store.commit("setRewardToEdit", selectedReward);
        this.$router.push({ name: "Rewards" });
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 5px;
  margin: 0px;
}

li {
  padding: 5px;
  margin: 5px;
}

.welcome {
  /* color: #f1841f; */
  color: rgb(253, 75, 5);
}

.marketing {
  color: rgb(13, 13, 14);
  font-weight: bold;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  text-decoration: none;
}

.header1 {
  color: rgb(13, 13, 14);
  font-weight: bold;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  text-decoration: none;
}
</style>
